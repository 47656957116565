h2 {
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
  
    @media (max-width: 768px) {
        line-height: 68px;
    }
}

h3 {
    font-weight: 400;
    margin-bottom: 20px;
}