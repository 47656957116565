.place {
  background: #E1C8A8;
    position: relative;
    z-index: 1;
    text-align: center;

    &__wrapper {
        padding-top: 60px;
        padding-bottom: 60px;

        @media (min-width: 769px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 992px) {
            padding-top: 140px;
            padding-bottom: 140px;
        }

        h3 {
            margin-bottom: 25px;
        }
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-button-prev:after, .swiper-button-next:after {
        color: #e72525;
        font-size: 30px;
      }
}