.header {
    height: 65px;
    background: black;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    &__wrapper {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 50px;

        img {
            width: 100%;
        }
    }

    &__main-menu {
        width: 50px;

        li {
            padding: 15px;
        }
    }

    &__mob-menu {
        a {
            display: flex;
            align-items: center;
            height: 55px;
            color: black;
            width: 230px;
            font-size: 32px;

            &:hover {
                background: black;
                color: white;
            }
        }
    }

    &__desc-menu {
        * {
            color: white;
        }

        ul {
            display: flex;

            li {
                padding: 0 15px;

                a {
                    position: relative;

                    &::before {
                        position: absolute;
                        bottom: -5px;
                        content: '';
                        width: 0;
                        height: 1px;
                        background: #e72525;
                        transition: all .3s;
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__links {
        * {
            color: white;
        }

        display: flex;

        .lang-switch {
            a {
                transition: all .3s;
                cursor: pointer;
        
                &:hover {
                    color: #e72525;
                }
            }
        }
    }
}