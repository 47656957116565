.services {
    background: #E1C8A8;
    position: relative;
    z-index: 1;
    text-align: center;

    &__wrapper {
        padding-top: 60px;
        padding-bottom: 60px;

        @media (min-width: 769px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 992px) {
            padding-top: 140px;
            padding-bottom: 140px;
        }

        h3 {
            margin-bottom: 40px;
            font-weight: 400;

            ul {
                list-style: circle;
                width: fit-content;
                max-width: 305px;
                margin-top: 10px;
                margin-left: 20px;

                li {
                    text-align: left;
                }
            }
        }
    }

    &__cards-block {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(1, 1fr);

        @media (min-width: 769px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__show {
        overflow: hidden;
        height: 0;
        margin-top: 20px;
        transition: all 1s;
    }

    &__show-btn {
        margin-top: 20px;
        border-radius: 50px;
        border: 1px solid black;
        padding: 15px;
        cursor: pointer;
        background: #e72525;
        border: 2px solid #e72525;
        color: white;
        transition: all .3s;

        &:hover {
            background: black;
            color: #e72525;
        }
    }
}