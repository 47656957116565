.service-card {
    padding: 3px;

    &__wrapper {
        background: white;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
        padding: 15px;
        max-height: 260px;
        min-height: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        overflow-y: auto;

        h3 {
            margin-bottom: 0;
        }

        span {
            font-size: 30px;
        }
    }
}