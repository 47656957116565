.banner {
    background-image: url(../assets/img/main_banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    position: relative;
    z-index: 1;

    @media (min-width: 769px) {
        padding-top: 20px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: black;
        opacity: 0.3;
    }

    &__wrapper {
        position: relative;
        min-height: calc(90vh - 65px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    
        h1 {
            margin-bottom: 20px;
            color: white;
            font-weight: 400;
            font-size: 74px;
            line-height: 86px;

            @media (max-width: 768px) {
                font-size: 64px;
                line-height: 78px;
            }
        }
    
        h3 {
            color: white;
            font-weight: 400;
            font-size: 36px;
            margin-bottom: 20px;
        }
    
        a {
            padding: 20px;
            font-size: 34px;
            font-weight: 600;
            border-radius: 50px;
            background: #e72525;
            border: 2px solid #e72525;
            color: white;
            transition: all .3s;

            &:hover {
                background: black;
                color: #e72525;
            }
        }
    }
}