.masters {
    background: #e72525;
    position: relative;
    z-index: 1;
    text-align: center;

    &__wrapper {
        padding-top: 60px;
        padding-bottom: 60px;

        @media (min-width: 769px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 992px) {
            padding-top: 140px;
            padding-bottom: 140px;
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        max-width: 300px;
        gap: 30px;
        margin: auto;

        p {
            font-size: 24px;
            font-weight: 600;
        }

        @media (min-width: 769px) {
            grid-template-columns: repeat(2, 1fr);
            max-width: 600px;
        }
    }

    &__photo {
        overflow: hidden;
        height: 0;
        padding-top: 120%;
        margin-bottom: 30px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            background-repeat: no-repeat;
            background-size: cover !important;
            background-position: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &--first {
            &::before {
                background: url('/assets/img/photo_2023-11-12_12-02-56.jpg');
            }
        }

        &--second {
            &::before {
                background: url('/assets/img/photo_2023-11-12_12-03-01.jpg');
            }
        }
    }
}