.contacts {
    background: black;
    position: relative;
    z-index: 1;
    text-align: center;

    &__wrapper {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        p {
          font-size: 16px;
          color: white;
          margin-bottom: 15px;
        }

        a {
          &:hover {
            * {
              color: #e72525;
              fill: #e72525;
            }
          }
        }
    }

    &__adress {
      width: 50%;
    }

    &__work-time {
      width: 50%;
    }

    &__social {
      width: 100%;

      a {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    &__copyrite {
      width: 100%;
      margin-top: 30px;

      p {
        width: 100%;
      }
    }
}