* {
    margin: 0;
    box-sizing: border-box;
}

/* ---------------- Navbar and burger menu ------------------ */
nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.burger-menu {
    height: 35px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

.burger-bar {
    width: 100%;
    height: 5px;
    background-color: white;
    border-radius: 5px;
}

.menu {
    width: 100%;
    height: 100vh;
    background-color: white;
    position: absolute;
    top: 0;
    right: 100%;
    z-index: -1;
    transition: all ease-out 0.5s;
    top: 65px;
}

/* ------------- sliding menu ------------------ */

.hidden {
    display: inherit;
    right: 100%;
}

.visible {
    display: inherit;
    right: 0;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate(5px, 14px);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2){
    transform: scale(0.1);
    opacity: 0;
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3){
    transform: rotate(135deg) translate(-8px, 16px);
    transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}