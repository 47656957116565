.fast-call {
    opacity: 0;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 100px;
    height: 100px;
    background: #e72525;
    border-radius: 50px;
    border: 2px solid black;
    color: white;
    transition: all .3s;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.show {
      opacity: 1;
    }

    &:hover {
        border-color: #e72525;
        background: black;
        color: #e72525;
    }
}